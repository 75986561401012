/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import { loadTranslations } from '@angular/localize';

loadTranslations({
  'logout': 'se déconnecter',
  'home': 'accueil',
  'accounts': 'comptes',
  'account': 'compte',
  'disputes': 'réclamations',
  'dispute': 'réclamation',
  'search': 'rechercher',
  'summaries': 'historique',
  'statement': 'état de compte',
  'message': 'message',
  'send_message': 'envoyer un message',
  'no_filters_applied': 'aucun filtre appliqué',
  'no_data': 'aucune donnée',
  'filters': 'filtres',
  'search_for_an_account': 'rechercher un compte',
  'caption': 'libellé',
  'invoice': 'facture',
  'amount': 'montant',
  'invoice_date': 'date de facture',
  'reset_selection': 'réinitialiser la sélection',
  'history': 'historique',
  'contact': 'contact',
  'contact_details': 'coordonnées du contact',
  'comment': 'commentaire',
  'comments': 'commentaires',
  'add_comment': 'ajouter un commentaire',
  'current_selection': 'sélection courante',
  'close': 'fermer',
  'send': 'envoyer',
  'sent': 'envoyé',
  'status': 'statut',
  'date': 'date',
  'description': 'description',
  'entity': 'entité',
  'name': 'nom',
  'legal_id': 'identifiant fiscal',
  'entry': 'écriture',
  'entries': 'écritures',
  'author': 'auteur',
  'subject': 'sujet',
  'overdue': 'échu',
  'outstanding': 'encours',
  'category': 'catégorie',
  'lettering': 'lettrage',
  'partial': 'partiel',
  'lettered': 'lettré',
  'opened': 'ouvert',
  'documents': 'documents',
  'download_file': 'télécharger le fichier',
  'remove': 'retirer',
  'no_name': 'sans nom',

  'paginator.items_per_page': 'Éléments par page',
  'paginator.next_page': 'Page suivante',
  'paginator.previous_page': 'Page précédente',
  'paginator.first_page': 'Première page',
  'paginator.last_page': 'Dernière page',
  'paginator.of': 'sur',

  'app.title': 'Portail Cash & Credit',

  'dispute.index_title': 'Suivre les réclamations',
  'dispute.index_subtitle': 'Cette liste présente les réclamations actuellement ouvertes',
  'dispute.no_dispute_found': 'Aucune réclamation trouvée',
  'dispute.one_dispute_found': 'réclamation trouvée',
  'dispute.many_disputes_found': 'réclamations trouvées',
  'dispute.assigned_filter': 'Qui me concerne',
  'dispute.age_in_days_column': 'Âge (jours)',

  'account.search_title': 'Rechercher des comptes',
  'account.search_subtitle': 'Saisissez vos critères de recherche',
  'account.search_account_filter': 'Référence ou nom de compte',
  'account.search_no_entries_filter': 'Comptes sans écritures',
  'account.no_account_found': 'Aucun compte trouvé',
  'account.one_account_found': 'compte trouvé',
  'account.many_accounts_found': 'comptes trouvés',

  'account.statement_title': 'Filtrer les écritures',
  'account.statement_subtitle': 'Cette liste présente toutes les écritures liées au compte',
  'account.no_entry_found': 'Aucune écriture trouvée',
  'account.one_entry_found': 'écriture trouvée',
  'account.many_entries_found': 'écritures trouvées',
});

/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 */
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags.ts';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.


/***************************************************************************************************
 * APPLICATION IMPORTS
 */
